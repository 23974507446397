import Papa from 'papaparse';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '../../../components/UI/Card/Card';
import CardBody from '../../../components/UI/Card/CardBody';
import CardFooter from '../../../components/UI/Card/CardFooter';
import Button from '../../../components/UI/CustomButtons/Button';
import GridContainer from '../../../components/UI/Grid/GridContainer';
import GridItem from '../../../components/UI/Grid/GridItem';
import * as actions from '../../../store/actions';
import classes from './CreateAgilePurchases.css';

class CreateAgilePurchases extends Component {
  state = {
    agilePurchasesToAdd: [],
  };

  componentDidMount() {
    this.props.onResetAgilePurchasesUploaded();
  }

  fileChangedHandler = event => {
    const csvFile = event.target.files[0];
    Papa.parse(csvFile, {
      complete: (results, file) => {
        this.setState({ agilePurchasesToAdd: results.data });
      },
    });
  };

  handleSubmit = async () => {
    try {
      await this.props.onCreateAgilePurchases(this.state.agilePurchasesToAdd);
      this.props.setToast(`Se han subido ${this.props.createdAgilePurchasesCount} comercios exitosamente.`);
    } catch {
      this.props.setToast(
        'Ha ocurrido un error en la creación de comercios. Por favor, revisa los datos e intenta nuevamente.',
        'danger',
      );
    }
  };

  render() {
    return (
      <>
        <Card profile>
          <CardBody profile>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <input
                  type="file"
                  onChange={event => this.fileChangedHandler(event)}
                  accept="csv"
                  multiple={false}
                  className={classes.FileInput}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              disabled={!this.state.agilePurchasesToAdd.length > 0}
              onClick={this.handleSubmit}
              className={classes.SubmitButton}
            >
              CREAR COMERCIOS
            </Button>
          </CardFooter>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.agilePurchases.loading,
    uploaded: state.agilePurchases.uploaded,
    createdAgilePurchasesCount: state.agilePurchases.createdAgilePurchasesCount,
  };
};

const mapDispatchToProps = dispatch => ({
  onCreateAgilePurchases: agilePurchases => dispatch(actions.createAgilePurchases(agilePurchases)),
  onResetAgilePurchasesUploaded: () => dispatch(actions.resetAgilePurchasesUploaded()),
  setToast: (message, messageType) => dispatch(actions.setToast(message, messageType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAgilePurchases);
