import axios from '../../axios';
import * as actionTypes from './actionTypes';

const fetchAgilePurchasesStart = () => {
  return {
    type: actionTypes.FETCH_AGILE_PURCHASES_START,
  };
};

const fetchAgilePurchasesSuccess = agilePurchases => {
  return {
    type: actionTypes.FETCH_AGILE_PURCHASES_SUCCESS,
    payload: agilePurchases,
  };
};

const fetchAgilePurchasesFailed = error => {
  return {
    type: actionTypes.FETCH_AGILE_PURCHASES_FAILED,
    error,
  };
};

export const fetchAgilePurchases = () => async dispatch => {
  dispatch(fetchAgilePurchasesStart());
  try {
    const { data } = await axios.get('/agile-purchase');
    dispatch(fetchAgilePurchasesSuccess(data.data));
  } catch (err) {
    dispatch(fetchAgilePurchasesFailed(err));
  }
};

const createAgilePurchasesSuccess = createdAgilePurchasesCount => {
  return {
    type: actionTypes.CREATE_AGILE_PURCHASES_SUCCESS,
    createdAgilePurchasesCount,
  };
};

const createAgilePurchasesFailed = error => {
  return {
    type: actionTypes.CREATE_AGILE_PURCHASES_FAILED,
    error,
  };
};

export const createAgilePurchases = agilePurchases => async dispatch => {
  try {
    const { data } = await axios.post('/agile-purchase/bulk', {
      agilePurchases: agilePurchases,
    });
    dispatch(createAgilePurchasesSuccess(data.data.createdAgilePurchasesCount));
  } catch (err) {
    throw dispatch(createAgilePurchasesFailed(err));
  }
};

export const resetAgilePurchasesUploaded = () => {
  return {
    type: actionTypes.RESET_AGILE_PURCHASES_UPLOADED,
  };
};

const deleteAgilePurchasesStart = () => {
  return {
    type: actionTypes.DELETE_AGILE_PURCHASES_START,
  };
};

const deleteAgilePurchasesSuccess = () => {
  return {
    type: actionTypes.DELETE_AGILE_PURCHASES_SUCCESS,
  };
};

const deleteAgilePurchasesFailed = error => {
  return {
    type: actionTypes.DELETE_AGILE_PURCHASES_FAILED,
    error,
  };
};

export const deleteAgilePurchases = agilePurchases => async dispatch => {
  dispatch(deleteAgilePurchasesStart());
  try {
    await axios.delete('/agile-purchase/bulk', {
      data: { ids: agilePurchases },
    });
    dispatch(deleteAgilePurchasesSuccess());
    dispatch(fetchAgilePurchases());
  } catch (err) {
    throw dispatch(deleteAgilePurchasesFailed(err));
  }
};
